import "./App.css";
import logo from "./assets/companylogo.png";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import { Image, Navbar } from "react-bootstrap";
import { Link, Route, Routes } from "react-router-dom";
import { SignInButton } from "./components/SignInButton";
import { SignOutButton } from "./components/SignOutButton";
import { useAccountData } from "./hooks/useAccountData";
import HomePage from "./pages/home/home";
import EditPostPage from "./pages/posts/EditPostPage";
import PostsPage from "./pages/posts/PostsPage";
import UploadPage from "./pages/upload/upload";


const App = () => {
  const {  isAuthenticated } = useAccountData();

  return (
      <><Navbar variant="light" bg="light">
      <Navbar.Brand>
        <Link to="/">
          <Image src={logo} alt="logo" className="Nav-logo"></Image>
        </Link>
      </Navbar.Brand>
      {isAuthenticated ? <SignOutButton /> : <SignInButton />}
    </Navbar><div className="wrapper">
        <AuthenticatedTemplate>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/posts" element={<PostsPage />} />
            <Route path="/edit" element={<EditPostPage />} />
            <Route
              path="/upload"
              element={<UploadPage />} />
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div className="container">
            <p>You are not signed in! Please sign in.</p>
          </div>
        </UnauthenticatedTemplate>
        <div className="push"></div>
        <footer className="footer"></footer>
      </div></>
  );
};

export default App;
