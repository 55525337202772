import FormData from "form-data";
import apiClient from "../api/client";
import { AcademyPost } from "../models/academyPostResponseModel";
import { CreateNewAcademyPost } from "../models/createNewPost";
import { UpdateAcademyPost } from "../models/updateAcademyPost";

export async function createNewPostAxios(post: CreateNewAcademyPost) {
  const bodyParam = {
    created_by: post.created_by,
    title: post.title,
    category_id: post.category_id,
    highlight: post.highlight,
    language: post.language,
    nationality: post.nationality,
    subscription_type_id: post.subscription_type_id,
    tags: post.tags,
    description: post.description,
    video: post.video,
    coverImage: post.coverImage,
    push_notification_message: post.push_notification_message,
  };

  const mediaFileList = [];
  mediaFileList.push(post.video);
  mediaFileList.push(post.coverImage);
  //delete post.mediaFileList;
  console.log(JSON.stringify(bodyParam));
  const data = new FormData();
  data.append("content", JSON.stringify(bodyParam));

  mediaFileList.forEach((file) => {
    data.append("files", file);
  });

  return apiClient.post<CreateNewAcademyPost>("academy/create", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function getAcademyPostsByMainCategoryAxios(category_id: string) {
  return apiClient.get<AcademyPost[]>(
    `academy/category/${category_id}/0/posts/`
  );
}

export async function updateAcademyPostAxios(
  postId: string,
  post: UpdateAcademyPost
) {
  console.log(JSON.stringify(post));
  const coverImage = post.coverImage;
  const data = new FormData();
  data.append("content", JSON.stringify(post));
  data.append("files", coverImage);

  return apiClient.put<UpdateAcademyPost>(
    `/academy/post/${postId}/update`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export async function deleteAcademyPostAxios(postId: string) {
  return apiClient.delete<string>(`academy/post/${postId}`);
}
