import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

import { useState } from 'react';
import { useAccountData } from '../../hooks/useAccountData';
import './home.css';


const HomePage = () => {
    const { isAdmin, accountId, accountType, isAuthenticated,accounts} = useAccountData();
    //const [accountId, setaccountId] = useState();
    const [uploadclick, setUploadClick] = useState(false);


    const [loading, setloading] = useState(false);

    const handleUploadScreen = ()=> {
        setUploadClick(true);
        
    }



    if(isAuthenticated){
    return (
        <div className='container'>
            <div className='body-home'>
                <p>
                    Hello {accounts[0].name}!
                    </p>
            <div className='button-container'>
                <Link to={{
                    pathname:'/upload',
                    state: accountId,
                }}>
                    <Button  variant='info' style={{ width:"100%", margin:10, backgroundColor:"#B0D7E1", borderColor:"#B0D7E1" }}>Upload</Button>
                </Link>
                <Link to='/posts'> 
                    <Button variant='info' style={{width:"100%", margin:10, backgroundColor:"#B0D7E1", borderColor:"#B0D7E1" }}>See posts</Button>
                </Link>
            </div>
        </div>  
    </div>    
    )

}
}

export default HomePage;

