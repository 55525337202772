import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { loginRequest } from "../authConfig";


/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <Button variant="light" bg="light" className="ml-auto" onClick={() => handleLogin("redirect")}>Sign in</Button>
    );
}