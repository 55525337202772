import { useEffect, useReducer, useRef, useState } from "react";
import {
  Badge,
  Button,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
} from "react-bootstrap";
import { readRemoteFile } from "react-papaparse";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  CategoryResponseModel,
  getAcademyCategoriesAxios,
} from "../../service/categoryService";
import { updateAcademyPostAxios } from "../../service/postService";

type Nationality = {
  value: string;
  label: string;
};

const EditPostPage = () => {
  const location = useLocation();
  const post = location?.state?.data;
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [successfulUpdate, setSuccessfulUpdate] = useState<boolean>(false);
  const [nationalities, setNationalities] = useState<Nationality[]>([]);
  const [source, setSource] = useState<string>();
  const subscriptionType = [
    { type: "Basic", id: "a9596b06-7079-43b6-a437-4cb52f3add39" },
    { type: "Premium", id: "4594e6e8-6f13-4606-ba26-b750cfa8feb0" },
  ];
  const [tags, setTags] = useState<string[]>([]);
  const [categories, setCategories] = useState<CategoryResponseModel[]>();
  const [mainCategories, setMainCategories] =
    useState<CategoryResponseModel[]>();
  const [subCategories, setSubCategories] = useState<CategoryResponseModel[]>();
  const [dropdownTitle, setdropdownTitle] = useState("Choose main categroy");
  const [subdropdownTitle, setsubdropdownTitle] = useState(
    "Choose sub categroy"
  );

  const initialState = {
    id: post?.id,
    created_by: post?.created_by,
    created_at: post?.created_at,
    updated_at: post?.updated_at,
    category_id: post?.category_id,
    title: post.title,
    description: post.description,
    highlight: post.highlight,
    language: post.language ?? "",
    nationality: post.nationality ?? "",
    tags: post.tags ?? "",
    coverImage: post?.media_content?.coverImage,
    subscription_type_id:
      post.subscription_type.type === "basic"
        ? "a9596b06-7079-43b6-a437-4cb52f3add39"
        : "4594e6e8-6f13-4606-ba26-b750cfa8feb0",
  };

  const reducer = (state: any, action: any) => {
    const result = { ...state };
    result[action.type] = action.value;
    return result;
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const inputRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    fetchCategories();
    setSource(post?.media_content?.coverImage[0]?.url);
    const nationalityCsv = require("../../assets/Nationality_List.csv");
    readRemoteFile(nationalityCsv, {
      complete: (results) => {
        let nationalitylist: Nationality[] = [];
        results.data.forEach((nat: any) => {
          const natobj = { value: nat[0], label: nat[0] };
          nationalitylist.push(natobj);
        });
        setNationalities(nationalitylist);
      },
      download: true,
    });
    setTags(post.tags);
  }, []);

  const fetchCategories = async () => {
    try {
      const res = await getAcademyCategoriesAxios();
      const allcategories = res?.data.academy_categories;
      setCategories(allcategories);
      const mainCategories: CategoryResponseModel[] = [];
      allcategories.forEach((c) => {
        if (!c.parent_category_id) {
          mainCategories.push(c);
        }
      });
      findMainAndSubCategory(post?.category_id, allcategories);
      setMainCategories(mainCategories);
    } catch (e) {
      console.log(e);
    }
  };

  const findMainAndSubCategory = (
    id: string,
    categories: CategoryResponseModel[]
  ) => {
    const category = categories.find((c) => c.id === id);
    if (category) {
      setsubdropdownTitle(category.title);
      const maincategory = categories.find(
        (c) => c.id === category.parent_category_id
      );
      setdropdownTitle(maincategory?.title ?? "");

      const getsubCategories: CategoryResponseModel[] = [];
      categories?.forEach((c) => {
        if (c.parent_category_id === maincategory?.id) {
          getsubCategories.push(c);
        }
      });
      setSubCategories(getsubCategories);
    }
  };

  const handleMainCategory = (event: any) => {
    const getsubCategories: CategoryResponseModel[] = [];
    categories?.forEach((c) => {
      if (c.parent_category_id === event.id) {
        getsubCategories.push(c);
      }
    });
    const categoryId = event.id;
    setSubCategories(getsubCategories);
    setdropdownTitle(event.title);
    setsubdropdownTitle("Choose sub categroy");
  };

  const handleSubCategory = (event: any) => {
    setsubdropdownTitle(event.title);
    dispatch({ type: "category_id", value: event.id });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await handleEditPost();
    handleShow();
  };

  const handleEditPost = async () => {
    try {
      const res = await updateAcademyPostAxios(post.id, state);
      if (res.data) {
        setSuccessfulUpdate(true);
      }
    } catch (err: any) {
      console.error(err);
      // If an error occurs, set state so we know it failed
      setSuccessfulUpdate(false);
    }
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleGoBack = () => {
    handleClose();
    // navigate(-1);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
  };

  const handleNationality = (event: any) => {
    let nationalityList: any[] = [];
    event.forEach((e: any) => {
      nationalityList.push(e.label);
    });
    dispatch({ type: "nationality", value: nationalityList });
  };
  const handleFileChange = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e?.target as HTMLInputElement;
    const file = target.files![0];
    const url = URL.createObjectURL(file);
    setSource(url);
    // setimagefile(file);
    //academymedialist.push(file);
    dispatch({ type: "coverImage", value: file });
  };

  const removeTag = (index: number) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
    dispatch({ type: "tags", value: newTags });
  };

  const addTag = () => {
    const tag = (document.getElementById("tag-input") as HTMLInputElement)
      .value;
    if (tags.length === 0) {
      setTags([...tags, tag]);
      let tagList = [tag];
      (document.getElementById("tag-input") as HTMLInputElement).value = "";
      dispatch({ type: "tags", value: tagList });
      return;
    }
    if (!tag || tags.find((t) => t.toLowerCase() === tag.toLowerCase())) {
      return;
    }
    setTags([...tags, tag]);
    let tagList = [...tags, tag];
    (document.getElementById("tag-input") as HTMLInputElement).value = "";
    dispatch({ type: "tags", value: tagList });
  };

  return (
    <div className="container">
      <p>EDIT ACADEMY POST</p>
      <hr></hr>
      <Form
        className="form"
        ref={inputRef}
        style={{ width: "100%", maxWidth: 400 }}
        onSubmit={handleSubmit}
        key={post.id}
      >
        <Form.Group className="mb-3" controlId="formImageInput">
          <Form.Label>Upload cover image</Form.Label>
          <div className="ImageInput">
            <Form.Control
              style={{ backgroundColor: "#F9F4F2" }}
              type="file"
              name="image"
              onChange={handleFileChange}
              accept="image/*"
            />
            {source && (
              <img
                className="ImageInput_image"
                width="100%"
                height="100%"
                src={source}
                style={{ marginTop: "1rem" }}
              />
            )}
          </div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control
            style={{ backgroundColor: "#F9F4F2" }}
            required
            className="edit-input"
            onChange={handleChange}
            type="text"
            name="title"
            defaultValue={post?.title}
          />
        </Form.Group>
        <Form.Label>Main category</Form.Label>
        <DropdownButton variant="info" id="dropdown" title={dropdownTitle}>
          {mainCategories?.map((cat, index) => {
            return (
              <Dropdown.Item
                key={index}
                onClick={() => handleMainCategory(cat)}
                eventKey={cat.title}
                required
                style={{ width: "100%" }}
              >
                {cat.title}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>

        <Form.Group className="mb-3" controlId="formSubCategory">
          <Form.Label>Sub category</Form.Label>
          <DropdownButton variant="info" id="dropdown" title={subdropdownTitle}>
            {subCategories?.map((subCat, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  required
                  eventKey={subCat.title}
                  onClick={() => handleSubCategory(subCat)}
                >
                  {subCat.title}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            style={{ backgroundColor: "#F9F4F2" }}
            required
            onChange={handleChange}
            name="description"
            as="textarea"
            defaultValue={post?.description}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formTags">
          <Form.Label>Tags</Form.Label>
          <Form.Control
            style={{ backgroundColor: "#F9F4F2" }}
            id="tag-input"
            name="tags"
            aria-describedby="formTags"
          ></Form.Control>
          <Button id="addTagButton" onClick={addTag}>
            Add tag
          </Button>
          <ul id="tag-list">
            {tags.map((tag, index) => (
              <ul key={tag} id="tag-list-element">
                <Badge bg="primary" id="tag-pill">
                  {tag}
                  <p onClick={() => removeTag(index)} id="remove-tag">
                    x
                  </p>
                </Badge>
              </ul>
            ))}
          </ul>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formLanguage">
          <Form.Label>Language</Form.Label>
          <Form.Control
            required
            style={{ backgroundColor: "#F9F4F2" }}
            onChange={handleChange}
            name="language"
            aria-describedby="formLanguage"
            defaultValue={post?.language}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formNationality">
          <Form.Label>Nationality</Form.Label>
          <Select
            closeMenuOnSelect={false}
            isMulti
            maxMenuHeight={150}
            name="nationality"
            options={nationalities}
            onChange={(e) => handleNationality(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formSubscription">
          <Form.Label>Basic or premium</Form.Label>
          {subscriptionType.map((type) => (
            <Form.Check
              type="radio"
              name="subscriptionType"
              id={type.type}
              label={type.type}
              defaultChecked={
                post?.subscription_type.type === type.type.toLowerCase()
                  ? true
                  : false
              }
              required
              onChange={(e) => {
                dispatch({ type: "subscription_type_id", value: type.id });
              }}
            />
          ))}
        </Form.Group>
        <Form.Group className="mb-4" controlId="formHighlight">
          <Form.Check
            type="checkbox"
            id="highlightcheckbox"
            label="Highlight video"
            defaultChecked={post.highlight ?? false}
            onChange={(e) => {
              e.target.checked
                ? dispatch({ type: "highlight", value: true })
                : dispatch({ type: "highlight", value: false });
            }}
          />
        </Form.Group>
        <Button
          style={{
            backgroundColor: "#1C4F5A",
            borderColor: "#1C4F5A",
            color: "#FFFFFF",
          }}
          type="submit"
        >
          Edit post
        </Button>
      </Form>
      <Modal show={show} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>
            {successfulUpdate ? "Edit successfull" : "Something went wrong..."}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {successfulUpdate
              ? "The post was successfully edited!"
              : "There was an error while updating the post. Please try again."}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#1C4F5A",
              borderColor: "#1C4F5A",
              color: "#FFFFFF",
              width: "30%",
            }}
            variant="info"
            onClick={handleGoBack}
          >
            {successfulUpdate ? "Great!" : "Ok"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditPostPage;
