import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import apiClient from "../api/client";

interface AccountData {
  id: string;
  account_type: string;
  // Add more fields if needed
}

export function useAccountData() {
  const { accounts, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [accountId, setAccountId] = useState<string | null>(null);
  const [accountType, setAccountType] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchAccount() {
      // If not authenticated or we have no accounts in MSAL, do nothing
      if (!isAuthenticated || !accounts || accounts.length === 0) return;

      try {
        setLoading(true);
        const localAccountId = accounts[0].localAccountId;
        const res = await apiClient.get<{ accounts: AccountData[] }>(
          `academy/account/${localAccountId}`
        );
        console.log(res);
        const data = res?.data?.accounts?.[0];

        if (data) {
          setAccountId(data.id);
          console.log(data.id);
          setAccountType(data.account_type);
          setIsAdmin(data.account_type === "Admin");
        } else {
          //use msal to log out
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      } catch (error) {
        console.error("Failed to fetch account data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAccount();
  }, [accounts, instance, isAuthenticated]);

  return {
    isAdmin,
    accountId,
    accountType,
    isAuthenticated,
    accounts,
    loading,
  };
}
