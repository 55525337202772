import { useEffect, useReducer, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import { usePapaParse } from "react-papaparse";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  CategoryResponseModel,
  getAcademyCategoriesAxios,
} from "../../service/categoryService";
import { createNewPostAxios } from "../../service/postService";
import "./upload.css";

import { Badge, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useAccountData } from "../../hooks/useAccountData";

const reducer = (state: any, action: any) => {
  if (action.type === "reset") {
    return initialState;
  }
  if (action.type === "addFile") {
    return {
      ...state,
      mediaFileList: [...state.mediaFileList, action.value],
    };
  }

  const result = { ...state };
  result[action.type] = action.value;
  return result;
};

const initialState = {
  created_by: "",
  title: "",
  category_id: "",
  highlight: false,
  language: "",
  nationality: "",
  subscription_type_id: "",
  tags: [],
  description: "",
  image: "",
  video: "",
  mediaFileList: "",
  push_notification_message: "",
};

type Nationality = {
  value: string;
  label: string;
};

const UploadPage = () => {
  const { accountId } = useAccountData();

  const [categories, setCategories] = useState<CategoryResponseModel[]>();
  const [mainCategories, setMainCategories] =
    useState<CategoryResponseModel[]>();
  const [subCategories, setSubCategories] = useState<CategoryResponseModel[]>();
  const [chosenMainCategory, setChosenMainCategory] = useState<string>("");
  const [mainCategorySelected, setMainCategorySelected] = useState(false);
  const [dropdownTitle, setdropdownTitle] = useState("Choose main categroy");
  const [subdropdownTitle, setsubdropdownTitle] = useState(
    "Choose sub categroy"
  );
  const [nationality, setNationality] = useState<Nationality[]>([]);
  const location = useLocation();
  const [videofile, setvideofile] = useState<any>();
  const [imagefile, setimagefile] = useState<any>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [highlight, setHighlight] = useState(false);
  const { readRemoteFile } = usePapaParse();
  const [source, setSource] = useState<string>();
  const navigate = useNavigate();
  const inputiRef = useRef<HTMLFormElement>(null);
  const [tags, setTags] = useState<string[]>([]);
  const [show, setShow] = useState(false);
  const [successfulCreate, setSuccessfulCreate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [chosenSubCategory, setchosenSubCategory] =
    useState<CategoryResponseModel[]>();

  useEffect(() => {
    console.log(accountId);
    fetchCategories();
    dispatch({ type: "created_by", value: accountId });

    const nationalityCsv = require("../../assets/Nationality_List.csv");
    readRemoteFile(nationalityCsv, {
      complete: (results) => {
        let nationalitylist: Nationality[] = [];
        results.data.forEach((nat: any) => {
          const natobj = { value: nat[0], label: nat[0] };
          nationalitylist.push(natobj);
        });
        setNationality(nationalitylist);
      },
      download: true,
    });
  }, [accountId, readRemoteFile]);

  const subscriptionType = [
    { type: "Basic", id: "a9596b06-7079-43b6-a437-4cb52f3add39" },
    { type: "Premium", id: "4594e6e8-6f13-4606-ba26-b750cfa8feb0" },
  ];

  const fetchCategories = async () => {
    try {
      const res = await getAcademyCategoriesAxios();
      const allcategories = res?.data.academy_categories;
      setCategories(allcategories);
      const mainCategories: CategoryResponseModel[] = [];
      allcategories.forEach((c) => {
        if (!c.parent_category_id) {
          mainCategories.push(c);
        }
      });
      setMainCategories(mainCategories);
    } catch (e) {
      console.log(e);
    }
  };

  const handleMainCategory = (event: any) => {
    const getsubCategories: CategoryResponseModel[] = [];
    categories?.forEach((c) => {
      if (c.parent_category_id === event.id) {
        getsubCategories.push(c);
      }
    });
    const categoryId = event.id;
    console.log(categoryId);
    setSubCategories(getsubCategories);
    setChosenMainCategory(event);
    setdropdownTitle(event.title);
    setMainCategorySelected(true);
    setsubdropdownTitle("Choose sub categroy");
  };
  const handleSubCategory = (event: any) => {
    setchosenSubCategory(event);
    setsubdropdownTitle(event.title);
    dispatch({ type: "category_id", value: event.id });
  };

  const handelCreatNewPost = async () => {
    try {
      setIsLoading(true);
      const res = await createNewPostAxios(state);

      if (res.status === 200) {
        setSuccessfulCreate(true);
      }
    } catch (error) {
      console.log(error);
      setSuccessfulCreate(false);
    } finally {
      setIsLoading(false);
    }
  };

  let academymedialist: any[] = [];
  const [videosource, setvideoSource] = useState<string>();
  const handlevideoFileChange = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e?.target as HTMLInputElement;
    const file = target.files![0];
    const url = URL.createObjectURL(file);
    setvideoSource(url);
    const postedFileModel = {
      uri: url,
      type: file.type,
      originalname: file.name,
      size: file.size,
    };
    dispatch({ type: "video", value: file });
    setvideofile(file);
    academymedialist.push(file);
  };

  const handleFileChange = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e?.target as HTMLInputElement;
    const file = target.files![0];
    const url = URL.createObjectURL(file);
    setSource(url);
    setimagefile(file);
    academymedialist.push(file);
    dispatch({ type: "coverImage", value: file });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    let mediafilelist = [];
    mediafilelist.push(videofile, imagefile);
    e.preventDefault();
    await handelCreatNewPost();
    //dispatch({ type: "reset" });
    handleShow();
  };

  const handleClose = (e: any) => {
    setShow(false);
    if (successfulCreate) {
      navigate("/posts");
      //inputiRef?.current?.reset()
      e.preventDefault();
    }
  };
  const handleShow = () => setShow(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
  };

  const handleNationality = (event: any) => {
    let nationalityList: any[] = [];
    event.forEach((e: any) => {
      nationalityList.push(e.label);
    });
    dispatch({ type: "nationality", value: nationalityList });
  };

  const removeTag = (index: number) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const addTag = async () => {
    const tag = (document.getElementById("tag-input") as HTMLInputElement)
      .value;
    if (tags.length === 0) {
      setTags([...tags, tag]);
      let tagList = [tag];
      (document.getElementById("tag-input") as HTMLInputElement).value = "";
      dispatch({ type: "tags", value: tagList });
      console.log(tags + ", " + tagList);
      return;
    }
    if (!tag || tags.find((t) => t.toLowerCase() === tag.toLowerCase())) {
      return;
    }
    setTags([...tags, tag]);
    let tagList = [...tags, tag];
    console.log(tags + ", " + tagList);
    (document.getElementById("tag-input") as HTMLInputElement).value = "";
    dispatch({ type: "tags", value: tagList });
  };

  return (
    <div className="container">
      <p>UPLOAD ACADEMY VIDEO </p>
      <hr></hr>
      <Form
        className="form"
        ref={inputiRef}
        style={{ width: "100%", maxWidth: 400 }}
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3" controlId="formVideoInput">
          <Form.Label>Upload video</Form.Label>
          <div className="VideoInput">
            <Form.Control
              style={{ backgroundColor: "#F9F4F2" }}
              type="file"
              required
              name="video"
              onChange={handlevideoFileChange}
              accept=".mov,.mp4"
            />
            {videosource && (
              <video
                className="VideoInput_video"
                width="100%"
                height="300"
                controls
                src={videosource}
              />
            )}
          </div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formImageInput">
          <Form.Label>Upload cover image</Form.Label>
          <div className="ImageInput">
            <Form.Control
              style={{ backgroundColor: "#F9F4F2" }}
              type="file"
              required
              name="image"
              onChange={handleFileChange}
              accept="image/*"
            />
            {source && (
              <img
                className="ImageInput_image"
                width="100%"
                height="100%"
                src={source}
                style={{ marginTop: "1rem" }}
                alt="Cover preview"
              />
            )}
          </div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formTitle">
          <Form.Label>Title </Form.Label>
          <Form.Control
            style={{ backgroundColor: "#F9F4F2" }}
            required
            className="upload-input"
            onChange={handleChange}
            type="text"
            name="title"
          />
        </Form.Group>
        <Form.Label>Main category</Form.Label>
        <DropdownButton variant="info" id="dropdown" title={dropdownTitle}>
          {mainCategories?.map((cat, index) => {
            return (
              <Dropdown.Item
                key={cat?.id}
                onClick={() => handleMainCategory(cat)}
                eventKey={cat.title}
                required
                style={{ width: "100%" }}
              >
                {cat.title}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
        <>
          <Form.Group className="mb-3" controlId="formSubCategory">
            <Form.Label>Sub category</Form.Label>
            <DropdownButton
              variant="info"
              id="dropdown"
              title={subdropdownTitle}
            >
              {subCategories?.map((subCat, index) => {
                return (
                  <Dropdown.Item
                    key={subCat.id}
                    required
                    eventKey={subCat.title}
                    onClick={() => handleSubCategory(subCat)}
                  >
                    {subCat.title}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formSubscription">
            <Form.Label>Basic or premium</Form.Label>
            {subscriptionType.map((type) => (
              <Form.Check
                key={type.id}
                type="radio"
                name="subscriptionType"
                id={type.type}
                label={type.type}
                required
                onChange={(e) => {
                  dispatch({ type: "subscription_type_id", value: type.id });
                }}
              />
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              style={{ backgroundColor: "#F9F4F2" }}
              required
              onChange={handleChange}
              name="description"
              as="textarea"
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formTags">
            <Form.Label>Tags</Form.Label>
            <Form.Control
              style={{ backgroundColor: "#F9F4F2" }}
              id="tag-input"
              name="tags"
              aria-describedby="formTags"
            ></Form.Control>
            <Button id="addTagButton" onClick={addTag}>
              Add tag
            </Button>
            <ul id="tag-list">
              {tags.map((tag, index) => (
                <ul key={tag} id="tag-list-element">
                  <Badge bg="primary" id="tag-pill">
                    {tag}
                    <p onClick={() => removeTag(index)} id="remove-tag">
                      x
                    </p>
                  </Badge>
                </ul>
              ))}
            </ul>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formLanguage">
            <Form.Label>Language</Form.Label>
            <Form.Control
              required
              style={{ backgroundColor: "#F9F4F2" }}
              onChange={handleChange}
              name="language"
              aria-describedby="formLanguage"
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formNationality">
            <Form.Label>Nationality</Form.Label>
            <Select
              closeMenuOnSelect={false}
              isMulti
              maxMenuHeight={150}
              name="nationality"
              options={nationality}
              onChange={(e) => handleNationality(e)}
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="formHighlight">
            <Form.Check
              type="checkbox"
              id="highlightcheckbox"
              label="highlight video"
              onChange={(e) => {
                e.target.checked
                  ? dispatch({ type: "highlight", value: true })
                  : dispatch({ type: "highlight", value: false });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPushNotification">
            <Form.Label>Push notification message (optional)</Form.Label>
            <Form.Control
              style={{ backgroundColor: "#F9F4F2" }}
              onChange={handleChange}
              name="push_notification_message"
              aria-describedby="formPushNotification"
            ></Form.Control>
          </Form.Group>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
          >
            <Button
              style={{
                backgroundColor: "#1C4F5A",
                borderColor: "#1C4F5A",
                color: "#FFFFFF",
              }}
              type="submit"
              disabled={isLoading}
            >
              Create post
            </Button>
            {isLoading && <Spinner animation="border" role="status" />}
          </div>
        </>
      </Form>
      <Modal show={show} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>{successfulCreate ? "Success" : "Error"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {successfulCreate ? (
            <>
              <p>Your video was successfully uploaded.</p>
              <p>It will soon be ready to watch in Dribblr!</p>
            </>
          ) : (
            <p>There was an error uploading your video.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#1C4F5A",
              borderColor: "#1C4F5A",
              color: "#FFFFFF",
              width: "30%",
            }}
            variant="info"
            onClick={(e) => handleClose(e)}
          >
            {successfulCreate ? "Close" : "Try again"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadPage;
